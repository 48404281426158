//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  layout: "home",
  onlyIfFirstLoad: true,
  head: {
    titleTemplate: "AddCube",
  },
  data()
  {
    return {};
  },
  mounted()
  {
    // Register an event listener when the Vue component is ready
    window.addEventListener("resize", this.onResize);
    const _vue = this;
    this.$refs.bgImage.onload = () =>
    {
      _vue.$refs.bgImage.style.height = _vue.$refs.bgImage.naturalHeight + "px";
      this.onResize();
    };
  },
  methods: {
    onResize(_)
    {
      if (window.innerWidth > window.innerHeight && window.innerWidth > 768)
      {
        const height = this.$refs.bgImage.naturalHeight;
        const width = this.$refs.bgImage.naturalWidth;

        // The Width is our constant
        var coverWidth = document.body.offsetWidth;
        var downscale = coverWidth / width;
        this.$refs.imageArea.style.transform = `scale(${downscale})`;
        this.$refs.ftr.$el.style.transform = `scale(${width / coverWidth
          }) translateX(-50%)`;
        document.querySelectorAll(".zoomRestore").forEach(el => el.style.transform = `scale(${width / coverWidth})`);
        this.$refs.bgImage.style.width = this.$refs.imageArea.style.width =
          width + "px";
        this.$refs.bgBlur.style.height = this.$refs.bgImage.offsetHeight + "px";
      }
    },
  },
  beforeDestroy()
  {
    // Unregister the event listener before destroying this Vue instance
    window.removeEventListener("resize", this.onResize);
  },
};
